<template>
  <div class="file-loading">
    <v-progress-circular
        color="main"
        indeterminate
        class="mb-4"
    />
    <div>
      در حال جستجو فایل ها...
    </div>
  </div>
</template>

<script>
export default {
  name: "FileLoading",
}
</script>

<style scoped>
.file-loading {
  height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>